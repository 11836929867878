<template>
  <div :class="wrapperClasses" class="relative">
    <input
      v-bind="$attrs"
      ref="input"
      :class="effectiveInputClasses"
      class="rounded-md"
      :placeholder="placeholder"
      :value="editingValue"
      @input="input"
      @focus="focus"
      @blur="blur"
      @keydown.escape.prevent.stop="clear"
    >
    <div class="absolute right-3 w-3 h-2 pt-1 lg:pt-0 lg:w-4 lg:h-3 mr-0.5 stroke-current opacity-40 dark:opacity-75 cursor-pointer" style="top: calc(50% - 0.5rem)" @click.prevent.stop="clear">
      <yb-icon :icon="icon" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      required: true
    },
    placeholder: String,
    wrapperClasses: {
      type: String,
      default: 'w-32 lg:w-48 xl:w-64'
    },
    inputClasses: {
      type: String,
      default: 'ml-2 w-full py-1 px-2 yb-input'
    },
    placeholderClasses: {
      type: String,
      default: 'placeholder:text-xs placeholder:lg:text-sm'
    }
  },
  emits: ['clear', 'blur', 'focus', 'update:modelValue'],
  data() {
    return {
      currentValue: this.modelValue,
      focused: false
    }
  },
  computed: {
    editingValue: {
      get() {
        return this.currentValue
      },
      set(value) {
        this.currentValue = value
        this.$emit('update:modelValue', value)
      }
    },
    icon() {
      return this.editingValue ? 'delete' : 'search'
    },
    effectiveInputClasses() {
      const result = []
      result.push(this.inputClasses)
      result.push(this.placeholderClasses)
      return result
    }
  },
  watch: {
    modelValue(v) {
      this.editingValue = v
    }
  },
  methods: {
    input($event) {
      this.$nextTick(() => {
        if ($event.target) {
          this.editingValue = $event.target.value
        }
      })
    },

    focus($event) {
      if (!$event) {
        this.$refs.input.focus()
        return
      }
      this.focused = true
      this.$emit('focus', $event)
    },

    blur($event) {
      this.focused = false
      this.$emit('blur', $event)
    },

    clear($event) {
      const priorEditingValue = this.editingValue
      this.editingValue = ''
      if (priorEditingValue) {
        this.$emit('clear')
      } else {
        this.$emit('click', $event)
      }
      if (!this.$refs.input.disabled) {
        this.$refs.input.focus()
      }
    }
  }
}
</script>

<style scoped>
.yb-input::placeholder {
  @apply opacity-50 dark:opacity-100;
}
</style>
